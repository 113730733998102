import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

function ContactUs(props) {
  return (
    <>
      <SEO title="Contact Us | New Zealand Sightseeing Bus Tours - awesomeNZ" />
      <Layout
        layoutType="typography TourHolderPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../images/slideshow/beachfront.jpg")}
                alt=""
                class="first last image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={false}
        hasSliderBlurSmall={true}
        content={
          <>
            <div id="TourHolderPage" class="clearfix">
              <div class="title-div">
                <h1>Contact Us</h1>
                <h3>Got a question? We're here to help.</h3>
              </div>
              <div class="destination-content">
                {/* ... */}
                <h2 class="tour">Coronavirus Travel Advisory</h2>
                <p>
                  Please read this{" "}
                  <Link to="/travel-advisory-coronavirus">
                    important information about Coronavirus
                  </Link>
                </p>
                <p>&nbsp;</p>
                <h2 class="tour">Get in touch</h2>
                <p>
                  FREEPHONE (within NZ) <br />
                  <a href="tel:0800486877">0800 486 877</a>
                  <br />
                  Our contact centre is open 7 days a week from 6.30am - 6pm
                  <br /> Email:{" "}
                  <a href="mailto:info@awesomenz.com?subject=awesomeNZ Website Enquiry">
                    info@awesomenz.com
                  </a>
                </p>
                <p>&nbsp;</p>
                <h2 class="tour">Auckland</h2>
                <p>
                  <a
                    href="https://maps.google.co.nz/maps?q=intercity+coachlines&amp;hl=en&amp;ll=-36.849045,174.761238&amp;spn=0.008345,0.015932&amp;sll=-36.849080,174.761263&amp;layer=c&amp;cid=13137077682561058775&amp;cbp=13,72.42,,0,-6.14&amp;cbll=-36.84889,174.761312&amp;gl=nz&amp;hq=intercity+coachlines&amp;t=h&amp;z=17&amp;panoid=QCmjhN2JQhQJoCO-sA2XCw"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      title=""
                      src={require("../images/auckland-contact-map.jpg")}
                      alt="AwesomeNZ Auckland contact"
                    />
                  </a>
                </p>
                <p>
                  Find us in central Auckland in the InterCity bus terminal at
                  Sky City.
                </p>
                <p>
                  102 Hobson St
                  <br /> Auckland Central
                  <br /> New Zealand
                </p>
                <p>&nbsp;</p>
                <h2 class="tour">Bay of Islands</h2>
                <p>
                  <img
                    title=""
                    src={require("../images/bayofislands-contact-map.jpg")}
                    alt="AwesomeNZ Bay of Islands contact"
                  />
                </p>
                <p>
                  We're in the Maritime Building on the Paihia Wharf, next to
                  the bus stop.
                </p>
                <p>
                  The Maritime Building, Waterfront
                  <br /> PO Box 145
                  <br /> Paihia, Bay of Islands
                  <br /> New Zealand
                </p>
                {/* ... */}
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default ContactUs
